import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Trans } from "gatsby-plugin-react-i18next";

export const query = graphql`
  query Error404($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        seo={{
          metaTitle: "404: Not found",
          metaDescription: "It looks like you got lost",
          noindex: true,
        }}
      />
      <h1>
        <Trans>notFound</Trans>
      </h1>
      <p>
        <Trans>notFoundMessage</Trans>
      </p>
    </Layout>
  );
};

export default NotFoundPage;
